<template>
  <div>
    <b-card>
      <b-row>
        <!-- people group 1 -->
        <b-col md="6">
          <h6 class="text-primary font-weight-bold mb-2">
            Gruppo Attributi
          </h6>

          <!-- draggable -->
          <draggable
            :list="list1"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(listItem, index) in list1"
              :key="index"
              tag="li"
            >
              <div
                class="d-flex"
                @click="showDetailsAttribute(listItem)"
              >
                <b-avatar :text="listItem.name.slice(0,2)" />
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.name }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <!-- people group 2 -->
        <b-col
          md="6"
          class="mt-sm-2 mt-md-0"
        >
          <h6 class="text-primary font-weight-bold mb-2">
            Attributi Non Assegnati
          </h6>

          <!-- draggable -->
          <draggable
            :list="list2"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(listItem, index) in list2"
              :key="index"
              tag="li"
            >
              <div
                class="d-flex"
                @click="showDetailsAttribute(listItem)"
              >
                <b-avatar :text="listItem.name.slice(0,2)" />
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.name }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <!-- <b-col
          md="6"
          class="mt-1"
        >
          <prism
            language="javascript"
            class="rounded"
          >
            People Group 1: {{ list1 }}
          </prism>
        </b-col>

        <b-col
          md="6"
          class="mt-1"
        >
          <prism
            language="javascript"
            class="rounded"
          >
            People Group 2: {{ list2 }}
          </prism>
        </b-col> -->
      </b-row>
    </b-card>
    {{ selectedAttribute }}
    <b-card v-if="selectedAttribute.length > 0">
      <h4>Attributo Selezionato: {{ selectedAttribute[selectedAttribute.length - 1] }}</h4>
      <div
        v-for="(attributo) in selectedAttribute"
        :key="attributo.id"
      >
        <component
          :is="attributo.tag"
          :value="attributo.valore"
          :title="attributo.label"
          :radio-options="attributo.valoreOption"
          :select-options="attributo.selectOptions"
        />
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BListGroupItem, BAvatar, BRow, BCol, BCard, VBToggle, BButton, BCardText,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import SimpleInput from './customComponents/SimpleInput.vue'
import RadioInput from './customComponents/RadioInput.vue'
import SelectPushable from './customComponents/SelectPushable.vue'
/* import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css' */

export default {
  components: {
    BButton,
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    // Prism,
    draggable,
    SimpleInput,
    RadioInput,
    SelectPushable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      attributeToEdit: 'prova',
      listItemSelected: [],
      list1: [
        {
          id: 1,
          name: 'Dimensioni',
          attributi: [
            {
              id: '100',
              valore: '25',
              tag: 'SimpleInput',
              type: 'text',
              label: 'Larghezza',
            },
            {
              id: '101',
              valore: '10',
              tag: 'SimpleInput',
              type: 'text',
              label: 'Lunghezza',
            },
            {
              id: '102',
              valore: '30',
              tag: 'SimpleInput',
              type: 'text',
              label: 'Altezza',
            },
          ],
        },
        {
          id: 2,
          name: 'Radio Input',
          attributi: [
            {
              id: '103',
              tag: 'RadioInput',
              valoreOption: [
                { text: 'Item 1', value: 'first', disabled: false },
                { text: 'Item 2', value: 'second', disabled: false },
                { html: '<b>Item</b> 3', value: 'third', disabled: true },
                { text: 'Item 4', value: 'Item 4', disabled: false },
                { text: 'Item 5', value: 'E', disabled: false },
              ],
              label: 'Radio BAlori',
            },
          ],
        },
        {
          id: 3,
          name: 'vue select',
          attributi: [
            {
              id: 104,
              tag: 'SelectPushable',
              selectOptions: ['select1', 'select2', 'select3'],
              label: 'vue select',
            },
          ],
        },
        {
          id: 4,
          name: 'Marlin Hinchee',
          email: 'preholding@scuffly.co.uk',
        },
      ],
      list2: [
        {
          name: 'Leia Atienza',
          email: 'unmeasurableness@interlamellar.co.uk',
        },
        {
          name: 'Lashawna Vaudrainm',
          email: 'soaking@khubber.com',
        },
        {
          name: 'Liliana Henscheid',
          email: 'lecideine@turndown.org',
        },
        {
          name: 'Keven Kolter',
          email: 'nontenure@anglicanum.co.uk',
        },
      ],
    }
  },
  computed: {
    selectedAttribute() {
      return this.listItemSelected
    },
  },
  methods: {
    showDetailsAttribute(data) {
      // console.log(data.attributi)
      this.listItemSelected = [...data.attributi, data.name]
      // this.listItemSelected.name = data.name
    },
  },
}
</script>
